<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @download-click="downloadClick"
      @upload-click="uploadClick"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
    />
<!--    <import-modal-->
<!--      v-model:visible="modalVisible"-->
<!--      apiFuncName="costUpload"-->
<!--      @closeModal="handleCloseModal"-->
<!--      @resetTable="handleResetTable"-->
<!--    />-->
    <CostList ref="orgListRef"></CostList>
    <div ref="staffingExportModal">
    <a-modal v-model:visible="modalVisible" title="导入" class="importModal" @cancel="handleCloseModal" width="29.2vw" :z-index="10001" centered :getContainer="() => $refs.staffingExportModal">
      <template #footer>
        <a-space>
          <a-button @click="handleCloseModal" type="primary" ghost>取消</a-button>
          <a-button @click="handleUploadOk" type="primary" :disabled="fileList.length === 0 || !importStatus">确认</a-button>
        </a-space>
      </template>
      <div>
        <a-form
            ref="formRef"
            layout="vertical"
            :model="importStatus"
        >
          <a-form-item label="导入方式" name="importStatus" >
            <a-select
                v-model:value="importStatus"
                :options="importOps"
                placeholder="请选择"
                allowClear
                style="width: 50%"
            >
            </a-select>
          </a-form-item>
        </a-form>
      </div>
      <div>
        <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true"
                          accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.7z,.zip,.rar"
                          :beforeUpload="handleBeforeUpload"
        >
          <p class="ant-upload-drag-icon">
            <UploadOutlined :style="`color: ${ fileList.length > 0 ? '#CCCCCC' : '#3182CE'}`"/>
          </p>
          <p class="ant-upload-text">{{fileList.length > 0 ? '请先删除已上传的文件，再重新上传新文件' : '单击或拖动文件到此区域以上传'}}</p>
          <p class="ant-upload-hint">
            支持单个上传。严禁上传公司数据或其他信息安全文件
          </p>
        </a-upload-dragger>
      </div>
    </a-modal>
      <ModalBox v-model="importVisible" :ok="handleImportOk" :content="content" :showIconCom="showIconCom" />
    <notice-modal
        :modalVisible="errorModalVisible"
        modalWidth="20.8vw"
        cancelBtn="确认"
        @close-modal="errorModalVisible = false"
        title="导入失败"
    >
      <ExclamationCircleOutlined class="icon-position" />
      <div v-for="item in errorMessage" :key="item.id">{{ item }}</div>
    </notice-modal>
    </div>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import CostList from "./components/CostList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";
import {UploadOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import NoticeModal from "@/components/NoticeModal.vue";
import ModalBox from "@/components/ModalBox.vue";

export default defineComponent({
  name: "importCost",
  components: {
    ModalBox,
    NoticeModal,
    CostList,
    Filter,
    UploadOutlined,
    ExclamationCircleOutlined,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const orgListRef = ref();
    const handleSearch = (searchParams) => {
      orgListRef.value.searchClick(searchParams);
    };
    const modalVisible = ref(false);
    const uploadClick = () => {
      modalVisible.value = true;
    };
    const handleCloseModal = () => {
      modalVisible.value = false;
      importStatus.value = ''
      fileList.value = []
    };
    const handleResetTable = () => {
      orgListRef.value.doSearch();
    };

    const downloadClick = () => {
      $api.costTemplate().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const exportClick = () => {
      $api.exportCostData().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const fileList = ref([])
    const importStatus = ref('')
    const importVisible = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const errorModalVisible = ref(false);
    const errorMessage = ref([]);
    const importOps = ref([
      {
        value: "true",
        label: "全量导入",
      },
      {
        value: "false",
        label: "增量导入",
      },
    ]);
    const handleUploadOk = () => {
      content.value = `是否确认以${importStatus.value === 'true' ? '全量' : '增量'}的方式导入`
      showIconCom.value = 'ExclamationCircleOutlined'
      importVisible.value = true
    }
    const handleImportOk = async () => {
      console.log(importStatus.value)
      const formData = new FormData();
      fileList.value.forEach((item) => {
        formData.append("file", item);
      });
      await $api.costUpload(formData,importStatus.value).then(res => {
        if (res.data?.length) {
          errorMessage.value = res.data;
          modalVisible.value = false;
          importVisible.value = false
          errorModalVisible.value = true;
          importStatus.value = ''
          fileList.value = []
        } else {
          modalVisible.value = false;
          importVisible.value = false
          importStatus.value = ''
          fileList.value = []
          message.success(res.msg)
          orgListRef.value.doSearch();
        }
      })
      await orgListRef.value.getState()
    }
    let messageTimer = null;
    const handleBeforeUpload = (file, list) => {
      return new Promise(() => {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i]) {
            if (fileList.value[i].name === file.name) {
              // settimeout 防止弹出多个消息提示
              clearTimeout(messageTimer);
              messageTimer = setTimeout(() => {
                message.error("请不要选择重复的文件！");
              });
              return false;
            }
          }
        }
        const num = 1
        const info = "最多上传一个附件"
        if (list.length > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }
        if (fileList.value.length + 1 > num) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error(info);
          });
          return false;
        }
        if (file.size === 0) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件为空文件，请检查后再次上传!");
          });
          return false;
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件大小限制50MB，请检查后再次上传!");
          });
          return false;
        }

        if (!file.type.includes("application/msword") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
            !file.type.includes("application/vnd.ms-powerpoint") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
            !file.type.includes("application/vnd.ms-excel") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
            !file.type.includes("text/plain") &&
            !file.type.includes("application/pdf") &&
            !(file.name.slice(-3) === '.7z') &&
            !file.type.includes("application/x-zip-compressed") &&
            !(file.name.slice(-4) === '.rar')
        ) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("只可以上传doc,docx,ppt,pptx,xls,xlsx,txt,pdf,7z,zip,rar格式的文件！");
          });
          return false;
        }
        fileList.value = [...fileList.value, file];
      })
    };

    return {
      orgListRef,
      handleSearch,
      exportClick,
      downloadClick,
      modalVisible,
      uploadClick,
      handleCloseModal,
      handleResetTable,
      importStatus,
      importOps,
      fileList,
      handleUploadOk,
      handleBeforeUpload,
      importVisible,
      handleImportOk,
      errorModalVisible,
      errorMessage,
      content,
      showIconCom,
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
.app-container {
  .importModal{

    .ant-upload-text {
      font-size: 16px;
    }
    .ant-upload-hint {
      font-size: 14px;
    }
  }
  :deep(.content){
    position: relative;
    padding-left: 26px;
    font-size: 16px;
    line-height: 22px;
  }
  .icon-position{
    position: absolute;
    top: 3px;
    left: 4px;
    color: #DC5E4F;

  }
}
</style>
