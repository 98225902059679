<template>
  <section class="org-list">
    <a-table
      ref="orgListRef"
      :columns="columns"
      :data-source="orgList"
      rowKey="id"
      :scroll="{
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
    </a-table>
    <div class="message1" v-if="dataBoxShow">
      成本信息：
      <span>新增数据数:</span
      >{{ statusData.insertCount ? statusData.insertCount : 0 }}
      <span>, 更新数据数:</span
      >{{ statusData.updateCount ? statusData.updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        statusData.status == 1
            ? "进行中"
            : statusData.status == 2
                ? "已完成" : "已失败"
      }}
      <span>, 来源渠道:</span
      >{{ statusData.channel ? statusData.channel : "无" }}
      <span>, 操作人:</span
      >{{ statusData.operator ? statusData.operator : "无" }}
      <span>, 操作时间:</span
      >{{ statusData.operationTime ? statusData.operationTime : "无" }}
    </div>
    <div class="message2" v-if="noDataShow">
      成本信息：
      <span>新增数据数:</span
      >{{ statusData.insertCount ? statusData.insertCount : 0 }}
      <span>, 更新数据数:</span
      >{{ statusData.updateCount ? statusData.updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        statusData.status == 1
            ? "进行中"
            : statusData.status == 2
                ? "已完成" : "已失败"
      }}
      <span>, 来源渠道:</span
      >{{ statusData.channel ? statusData.channel : "无" }}
      <span>, 操作人:</span
      >{{ statusData.operator ? statusData.operator : "无" }}
      <span>, 操作时间:</span
      >{{ statusData.operationTime ? statusData.operationTime : "无" }}
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";

export default defineComponent({
  name: "CostCtrList",
  components: {},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const orgListRef = ref();
    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        ellipsis: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        ellipsis: true,
      },
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
      },
      {
        title: "Grade",
        dataIndex: "grade",
        key: "grade",
        ellipsis: true,
      },
      {
        title: "Tag1",
        dataIndex: "tag1",
        key: "tag1",
        ellipsis: true,
      },
      {
        title: "Tag2",
        dataIndex: "tag2",
        key: "tag2",
        ellipsis: true,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        ellipsis: true,
      },
      {
        title: "ADRC",
        dataIndex: "adrc",
        key: "adrc",
        ellipsis: true,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const orgList = ref();
    let searchCondition = {};

    const doSearch = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getCostData(params).then((res) => {
        pagination.total = res.data.total;
        orgList.value = res.data.resource;
        if (res.data.total === 0) {
          dataBoxShow.value = false;
          noDataShow.value = true;
        } else {
          dataBoxShow.value = true;
          noDataShow.value = false;
        }
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const statusData = ref({})
    const noDataShow = ref(false);
    const dataBoxShow = ref("");
    const getState = () => {
      $api.costexcelsStatus().then((res) => {
        statusData.value = res.data;
      });
    }

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
      getState()
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      orgListRef,
      columns,
      pagination,
      orgList,
      searchClick,
      tableHeight,
      doSearch,
      statusData,
      dataBoxShow,
      noDataShow,
      getState,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);
  position: relative;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
  .message1 {
    position: absolute;
    bottom: -20px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }

  .message2 {
    position: absolute;
    bottom: -1.5vw;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
}
</style>
