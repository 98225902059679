<template>
  <section class="top-filter">
    <div class="mt12 mb12">
    <a-form
      ref="formRef"
      class="form"
      layout="vertical"
      :model="formState"
    >
    </a-form>
    <div class="buttons-wrap right">
      <Tooltip title="导出">
        <a-button class="" type="primary" ghost @click="exportClick" v-auth="['system:cost:export']">
          <ExportOutlined />
        </a-button>
      </Tooltip>
      <Tooltip title="下载模板">
        <a-button class="ml12" type="primary" ghost @click="downloadClick" v-auth="['system:cost:download']">
          <DownloadOutlined />
        </a-button>
      </Tooltip>
      <Tooltip title="导入">
        <a-button class="ml12" type="primary" ghost @click="uploadClick" v-auth="['system:cost:import']">
          <ImportOutlined />
        </a-button>
      </Tooltip>
    </div>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onActivated } from "vue";
import { ExportOutlined, DownloadOutlined, ImportOutlined } from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "TopFilter",
  components: { ExportOutlined, DownloadOutlined, ImportOutlined,Tooltip },
  emits: [
    "search",
    "export-click",
    "download-click",
    "upload-click",
    "refresh-table",
  ],
  setup(props, ctx) {
    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      ctx.emit("search", formState);
    };

    const formState = reactive({
      status: undefined,
      condition: "",
    });
    const doSearch = () => {
      ctx.emit("search", formState);
    };
    const exportClick = () => {
      ctx.emit("export-click");
    };
    const downloadClick = () => {
      ctx.emit("download-click");
    };
    const uploadClick = () => {
      ctx.emit("upload-click");
    };
    // onMounted(() => {
    //   nextTick(doSearch);
    // });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      exportClick,
      downloadClick,
      uploadClick,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  }
.buttons-wrap{
  padding-top: 0;
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
